<template>
  <b-modal
    v-model="visible"
    hide-footer
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    centered
    :title="title"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">

        <b-form-group
          label-for="user-new-password"
          :label="$t('Tasdiqlash paroli')"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="form.id ? '' : 'required'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="user-new-password"
                v-model="form.confirm_code"
                name="new-password"
                placeholder="Tasdiqlash parol"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <b-overlay :show="loading">
      <b-button
        class="w-100"
        variant="primary"
        @click="save"
      >
        Tekshirish
      </b-button>
    </b-overlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        confirm_code: '',
      },
      title: '',
      visible: false,
      loading: false,
      required,
      passwordFieldTypeNew: 'password',
    }
  },
  computed: {
    itemId() {
      return this.$route.query.id
    },

  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
          if (this.itemInfo) {
            this.setForm()
          }
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Tekshirish kodi')

    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {

    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        let systemCode = null
        this.fetchSettings().then(res => {
          systemCode = res.data.data.filter(
            c => c.code == 'code_for_update_icloud_pass',
          )[0].value
          if (this.form.confirm_code == systemCode) {
            showToast(
              'success',
              this.$t('Tasdiqlandi'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          } else {
            showToast('danger', this.$t('Parol mos emas'), 'XIcon')
          }
        })

        this.loading = false
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      if (this.itemInfo) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      fetchSettings: 'setting/index',

    }),
  },
}
</script>

<style scoped></style>
