<template>
  <div>
    <span
      class="link"
      @click="back"
    > Назад </span>
    <div class="row mt-2">
      <div class="col-5">
        <!-- Клиент Info -->
        <h3>Информации клиента</h3>
        <div class="card p-2">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  colspan="2"
                  class="text-center"
                >
                  Клиент
                </th>
              </tr>
            </thead>
            <tbody v-if="cashInfo">
              <tr>
                <th>ФИО</th>
                <td>{{ cashInfo.bought_for_cash_client_full_name }}</td>
              </tr>
              <tr>
                <th>Телефон</th>
                <td>{{ cashInfo.bought_for_cash_client_phone }}</td>
              </tr>
              <tr>
                <th>Xarid summasi</th>
                <td>{{ cashInfo.amount|formatPrice }} so'm</td>
              </tr>
              <tr>
                <th>Izoh</th>
                <td>{{ cashInfo.comment }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- График погашения -->
      <div class="col-7">
        <h3>Информации продукта</h3>

        <div
          v-if="
            cashInfo &&
              cashInfo.products &&
              cashInfo.products[0].id
          "
          class="card p-2"
        >
          <table class="table table-hover">
            <thead>
              <tr>
                <th>№</th>
                <th>Nomi</th>
                <th>Soni</th>
                <th>Narxi</th>
                <th>Jami</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <template v-for="(product, index) in cashInfo.products">
                <tr :key="index">
                  <td>{{ index + 1 }}</td>
                  <td
                    v-if="product.id"
                    class="text-primary"
                    @click="
                      goTo('WarehouseProductInfo', {
                        id: product.product_category_id,
                      })
                    "
                  >
                    {{ product.category.name_uz }}
                    <p v-if="product.imei_1">
                      <b>IMEI 1: </b> {{ product.imei_1 }}
                    </p>
                    <p v-if="product.imei_2">
                      <b>IMEI 2: </b> {{ product.imei_2 }}
                    </p>
                  </td>
                  <td>{{ Number(product.product_quantity) }} dona</td>
                  <td v-if="product.id">
                    {{ Number(product.product_price_with_percent) | formatPrice }}so'm
                  </td>
                  <td v-if="product.id">
                    {{
                      (Number(product.product_price_with_percent) *
                        Number(product.product_quantity))
                        | formatPrice
                    }}so'm
                  </td>
                  <td>
                    <feather-icon
                      icon="Trash2Icon"
                      class="text-danger"
                      size="18"
                      @click="deleteModalOpen(product)"
                    />
                  </td>
                </tr>
              </template>
              <tr>
                <th colspan="2">
                  Jami
                </th>
                <th colspan="4">
                  {{ getTotalProduct() | formatPrice }}so'm
                </th>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- Trade In -->
        <h3>B/U telefon qabul qilish</h3>
        <div class="card p-2">
          <template v-if="parties && parties.data && parties.data.length">
            <table class="table table-bordered table-responsive">
              <thead>
                <tr>
                  <th>Partya</th>
                  <th>Sana</th>
                  <th>Izoh</th>
                  <th>Summa</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="party in parties.data"
                  :key="party.id"
                >
                  <td
                    class="text-primary"
                    @click="
                      goTo('warehouseParty-show', {
                        id: party.id,
                      })
                    "
                  >
                    {{ party.id }}
                  </td>
                  <td>{{ formatDateYmd(party.date) }}</td>
                  <td>{{ party.comment }}</td>
                  <td>{{ party.cost |formatPrice }}</td>
                </tr>

              </tbody>
            </table>

            <b-button
              variant="outline-success"
              class="btn-tour-skip mt-2"
              @click="show_party_create = true"
            >
              <span class="mr-25 align-middle">
                <feather-icon icon="PlusIcon" />
                {{ $t("Telefon qabul qilish") }}</span>
            </b-button>
          </template>
          <div
            v-else
            class="text-right"
          >
            <p>Mijozdan olib qolingan telefonni kiriting</p>
            <b-button
              variant="outline-success"
              class="btn-tour-skip"
              @click="show_party_create = true"
            >
              <span class="mr-25 align-middle">
                <feather-icon icon="PlusIcon" />
                {{ $t("Приход товара") }}</span>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <!-- List -->
    <b-modal
      v-model="openDeleteModal"
      centered
      size="xl"
      hide-footer
      no-close-on-backdrop
      @close="openDeleteModal = false"
      @cancel="openDeleteModal = false"
    >
      <h4 class="text-center">
        Информации продукта
      </h4>
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <th>Nomi</th>
            <th>Soni</th>
            <th>Narxi</th>
            <th>Jami</th>
            <th>Kimdan</th>
            <th>Qachon kelgan</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <template v-for="(product, index) in deletingProductItems">
            <tr :key="index">
              <td>
                {{ product.category ? product.category.name_uz : "" }}
              </td>
              <td>{{ product.product_quantity }} dona</td>
              <td>{{ product.product_price | formatPrice }}so'm</td>
              <td>
                {{ product.product_price_with_percent | formatPrice }}so'm
              </td>
              <td @click="goToPartyInfo(product.product.party)">
                <a href="#">
                  {{ product.product.party.provider.name }} ({{
                    product.product.party.provider.director_fio
                  }})
                </a>
              </td>
              <td>
                {{ product.product.party.date | toLocaleDateString }}
              </td>
              <td>
                <feather-icon
                  icon="Trash2Icon"
                  class="text-danger"
                  size="18"
                  @click="deleteProduct(product)"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </b-modal>
    <!-- Delete Confirmation -->
    <!--  -->
    <b-modal
      v-model="openDeleteConfirmationModal"
      centered
      hide-footer
      no-close-on-backdrop
      @close="openDeleteConfirmationModal = false"
      @cancel="openDeleteConfirmationModal = false"
    >
      <h4 class="text-center">
        Mahsulotni qaytarish
      </h4>
      <b-form class="auth-login-form mt-2 row">
        <b-form-group
          :label="$t('Miqdori')"
          label-for="amount"
          class="col-12"
        >
          <b-form-input
            id="address"
            v-model="form.rejected_quantity"
            type="number"
            name="name"
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          :label="$t('Izoh')"
          label-for="amount"
          class="col-12"
        >
          <b-form-textarea
            v-model="form.comment"
            placeholder="Sababini yozing"
            rows="5"
          />
        </b-form-group>
        <div class="mt-3 text-right">
          <b-button
            variant="success"
            @click="deleteAction()"
          >
            Tasdiqlash
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <!--  -->
    <div v-if="show_party_create">
      <b-modal
        v-model="show_party_create"
        size="xl"
        centered
        hide-footer
        no-close-on-backdrop
        @close="show_party_create = false"
        @cancel="show_party_create = false"
      >
        <div class="text-center">
          <PartyCreate
            :is-component="true"
            :contract-id="$route.params.id"
            :p-party-type="2"
            @save="getAction"
          />
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    PartyCreate: () => import('@/views/warehouse/parties/save.vue'),
  },
  data() {
    return {
      cashInfo: null,
      openDeleteModal: false,
      openDeleteConfirmationModal: false,
      deletingProductItems: [],
      getUsedPhone: false,
      show_party_create: false,
      form: {
        rejected_quantity: null,
        comment: null,
        contract_product_id: null,
      },
    }
  },
  computed: {
    ...mapGetters({
      parties: 'parties/GET_ITEMS',
    }),
    contractId() {
      return this.$route.params.id
    },
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
  },
  watch: {},
  mounted() {
    this.getAction()
    this.getPariesItems()
  },
  methods: {
    ...mapActions({
      getPartiesList: 'parties/index',
      getItemAction: 'contract/paymentShow',
      getPaymentProductInfo: 'contract/getPaymentProductInfo',
      rejectByProduct: 'contract/rejectByProduct',
      makePaymentPdf: 'resource/makePaymentPdf',
    }),
    async getPariesItems() {
      this.loading = true
      await this.getPartiesList({ payment_id: this.$route.params.id })
      this.loading = false
    },
    getTotalProduct() {
      let total = 0
      if (this.cashInfo) {
        this.cashInfo.products.forEach(product => {
          total
            += Number(product.product_price_with_percent) * Number(product.product_quantity)
        })
      }
      return total
    },

    deleteModalOpen(product) {
      this.openDeleteModal = true
      this.getPaymentProductInfo({
        payment_id: product.payment_id,
        product_category_id: product.product_category_id,
      }).then(res => {
        this.deletingProductItems = res.data
      })
    },
    deleteProduct(product) {
      this.openDeleteConfirmationModal = true
      this.form.contract_product_id = product.id
      this.form.rejected_quantity = product.product_quantity
    },
    deleteAction() {
      this.rejectByProduct(this.form).then(res => {
        this.openDeleteConfirmationModal = false
        this.openDeleteModal = false
        this.getAction()
      })
    },
    getAction() {
      this.show_party_create = false
      this.getItemAction({
        id: this.$route.params.id,
        relations: 'products.category|createdUser',
      }).then(resp => {
        if (resp.success) {
          this.cashInfo = resp.data
        }
      })
    },
    goToPartyInfo(party) {
      this.$router.push({
        name: 'warehouseParty-show',
        params: { id: party.id },
      })
    },
  },
}
</script>

<style></style>
