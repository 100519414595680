var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"hide-footer":"","cancel-variant":"outline-secondary","no-close-on-backdrop":"","centered":"","title":_vm.title},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-form-group',{attrs:{"label":_vm.$t('Ismi'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","name":"name","disabled":!!_vm.itemInfo,"placeholder":""},model:{value:(_vm.form.firstname),callback:function ($$v) {_vm.$set(_vm.form, "firstname", $$v)},expression:"form.firstname"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Familiyasi'),"label-for":"lastname"}},[_c('b-form-input',{attrs:{"id":"lastname","name":"lastname","disabled":!!_vm.itemInfo,"placeholder":_vm.$t('Familiya')},model:{value:(_vm.form.lastname),callback:function ($$v) {_vm.$set(_vm.form, "lastname", $$v)},expression:"form.lastname"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"mail"}},[_c('validation-provider',{attrs:{"name":"mail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"mail","disabled":!!_vm.itemInfo,"state":errors.length > 3 ? false : null,"name":"mail","type":"email","placeholder":_vm.$t('test@mail.com')},model:{value:(_vm.form.mail),callback:function ($$v) {_vm.$set(_vm.form, "mail", $$v)},expression:"form.mail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Mijoz telefoni'),"label-for":"v-phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Mijoz telefoni'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +998 ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"v-phone","disabled":!!_vm.itemInfo,"name":"v-phone","state":errors.length > 3 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Icloud telefoni'),"label-for":"v-phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Icloud telefoni'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +998 ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"v-phone","name":"v-phone","state":errors.length > 3 ? false : null},model:{value:(_vm.form.icloud_phone),callback:function ($$v) {_vm.$set(_vm.form, "icloud_phone", $$v)},expression:"form.icloud_phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"user-new-password","label":_vm.$t('Icloud paroli')}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":_vm.form.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"user-new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":"icloud parol"},model:{value:(_vm.form.icloud_password),callback:function ($$v) {_vm.$set(_vm.form, "icloud_password", $$v)},expression:"form.icloud_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"mail-password","label":_vm.$t('Email paroli')}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":_vm.form.id ? '' : 'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"mail-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":"Email parol"},model:{value:(_vm.form.mail_password),callback:function ($$v) {_vm.$set(_vm.form, "mail_password", $$v)},expression:"form.mail_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)]}}])}),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"w-100",attrs:{"variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Saqlash ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }