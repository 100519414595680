<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <b-form-group
          :label="$t('Oklad (so`m)')"
          label-for="oklad"
        >
          <validation-provider
            #default="{ errors }"
            name="oklad"
            rules="required"
          >
            <cleave
              id="price"
              v-model="form.oklad"
              class="form-control"
              :options="cleaveOption"
              :state="errors.length > 3 ? false:null"
              placeholder="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, Cleave,
  },
  data() {
    return {
      form: {
        id: null,
        oklad: null,
      },
      roles: [],
      passwordFieldTypeNew: 'password',
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      passwordFieldTypeRetype: 'password',
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordEqual() {
      return this.form.password === this.form.retype_password
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
  },
  mounted() {
    this.title = this.$t('Oklad qo`shish')
    this.$root.$on('slotAction', data => {
      this.visible = true
    })
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.getItem({ id: item.id, relations: 'hiredWorkerWarehouse|provider' }).then(res => {
          this.userName = res.data.provider.phone
      })
      this.form.id = item.id
      this.form.oklad = item.oklad
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'user/updateOklad',
      getItem: 'user/show',
      updateItem: 'user/updateOklad',
    }),
  },
}
</script>

<style scoped>

</style>
