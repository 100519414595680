<template>
  <div>
    <b-modal
      v-model="imageModal"
      centered
      size="xl"
      title="Mahsulot suratlarini yuklang"
      hide-footer
      no-close-on-backdrop
      @close="$emit('close', true)"
      @cancel="$emit('close', true)"
    >
      <h1>{{ productName }}</h1>

      <span
        v-if="!isHasNotUploaded"
        class="btn btn-outline-warning upload-label float-right text-right"
        @click="addImage"
      >
        <feather-icon
          icon="PlusSquareIcon"
          size="12"
        /> Rasmni qo'shish
      </span>

      <div class="w-50">
        <hr>
        <h4>Mavjud mahsulot malumotlarini yuklash</h4>
        <label for="category_id">Mahsulotni tanlang</label>
        <v-select
          id="category_id"
          ref="vSelect"
          v-model="product_name_search"
          :options="product_categories"
          :reduce="(option) => option.id"
          label="name_uz"
          placeholder="Mahsulot nomi bo'yicha izlash"
          @search="searchProduct"
          @input="selectedProduct"
        >
          <span slot="no-options"> Mavjud tovarlar bo'yicha qidiring </span>
        </v-select>
        <div v-if="newSelectedProductInfo">
          <template v-if="selectedPreviewSrcList && selectedPreviewSrcList.length">
            <h5 class="mt-3">
              Tanlangan mahsulot suratlari
            </h5>
            <list-view
              class="w-100"
              :photos="selectedPreviewSrcList"
            />
            <b-button
              class="mt-5 w-100"
              variant="primary"
              @click="setSelectedProduct"
            >
              Yuklash
            </b-button>
          </template>
          <h5
            v-else
            class="my-3"
          >
            Tanlangan mahsulotning suratlari mavjud emas
          </h5>
        </div>
        <hr>

      </div>

      <div class="d-flex justify-content-center">
        <div
          v-for="(photo, index) in imageData.photos"
          :key="index"
        >
          <div
            v-loading="loads['loading' + index]"
            class="text-center m-2"
          >
            <template v-if="photo.url">
              <img
                :src="storageUrl + photo.url"
                height="162"
                width="162"
                :class="photo.is_main ? 'img-uploaded-main' : 'img-uploaded'"
                @click="$refs.firstPassport[index].click()"
              >
              <span
                class="upload-change-label"
                @click="$refs.firstPassport[index].click()"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="12"
                />
                Rasmni o'zgartirish
                <template v-if="photo.is_main">(asosiy)</template>
              </span>
              <feather-icon
                v-if="imageData.photos.length > 1"
                icon="Trash2Icon"
                class="text-danger"
                size="18"
                @click="deleteImg(index)"
              />
            </template>
            <template v-else>
              <img
                src="@/assets/images/svg/fileuploader-placeholder.svg"
                class="img-placeholder"
                alt="image"
                width="162"
                @click="$refs.firstPassport[index].click()"
              >
              <span
                class="upload-label"
                @click="$refs.firstPassport[index].click()"
              >
                <feather-icon
                  icon="PlusSquareIcon"
                  size="12"
                /> Rasmni yuklash
              </span>
            </template>
            <input
              ref="firstPassport"
              accept="image/jpeg, image/jpg, image/png, image/webp"
              type="file"
              class="d-none"
              @change="(e) => onChangeFile(e, index, 'photo')"
            >
          </div>
        </div>
      </div>

      <div v-if="isHasNotUploaded">
        <span
          class="link"
          @click="searchGoogleImage"
        >Internetdan izlash</span>
        <div v-if="isGenerate">
          <b-form-group
            label="Internetdan izlash"
            label-for="search"
          >
            <b-form-input
              id="search"
              v-model="searchImage"
              name="search"
              placeholder="Surat nomini kiriting"
            />
          </b-form-group>
          <!-- <span
          v-if="generatedImages.length"
          class="text-muted mb-2"
        >Ushbu suratlarni yuklab olib, so'ng qayta
          yuklashingniz
          mumkin(Qidirishda ingliz tilida qidiring)!</span> -->
          <b-overlay :show="searchLoading">
            <div
              v-if="generatedImages.length"
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-around"
            >
              <div
                v-for="(item, index) in generatedImages"
                :key="index"
                class="mb-2 img-card-block"
              >
                <!-- <img
                :src="item.link"
                height="162"
                width="162"
                class="img-card"
              > -->
                <el-image
                  fluid
                  class="img-card"
                  :src="item.link"
                  :preview-src-list="generatedImages.map(p => p.link)"
                />
                <a
                  class="link"
                  @click="downloadImage(item.link, item.title)"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="12"
                  /> Rasmni yuklash
                </a>
              </div>
            </div>
            <div
              v-if="generatedImages.length"
              class="d-block text-center w-100"
            >
              <b-button
                variant="outline-success"
                class="mr-1 w-100"
                @click="showMoreImages"
              >
                Yana ko'rsatish
              </b-button>
            </div>
          </b-overlay>
        </div>
      </div>
      <div class="mt-2 text-right">
        <b-button
          variant="primary"
          class="mr-1"
          @click="send"
        >
          Yuklash
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { showToast } from '@/utils/toast'
import { mapActions } from 'vuex'
import axios from 'axios'
import { debounce } from 'debounce'
import vSelect from 'vue-select'
import ListView from '@/views/warehouse/products/productDetails/ListView.vue'

export default {
  components: { vSelect, ListView },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    productName: {
      type: String,
      default: '',
    },
    uploadedPhotos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imageModal: true,
      searchImage: '',
      generatedImages: [],
      per_page: 10,
      page: 1,
      searchLoading: false,
      isGenerate: false,
      cx: 'b1fc951201f67488b&key=AIzaSyC7T0mYv9cnoM98Z_3w5TLsGVZwRNXD7xc',
      cx2: '558fff20ea33d410e&key=AIzaSyAzE-MSB2sxM7G_WF1W0P0V9fQ8S3C2euc',
      imageData: {
        product_category_id: null,
        photos: [
          {
            url: '',
            is_main: 1,
          },
        ],
      },
      loads: {
        loading0: false,
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
      },
      product_categories: [],
      product_name_search: null,
      newSelectedProductInfo: null,
    }
  },
  computed: {
    storageUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/`
    },
    isHasNotUploaded() {
      return this.imageData.photos.some(p => p.url == '')
    },
    indexPhoto() {
      // const index = this.imageData.photos[0].url ? (this.imageData.photos.length - 1) : 0
      return (this.imageData.photos.length - 1)
    },
    selectedPreviewSrcList() {
      return this.newSelectedProductInfo?.photos.map(p => this.storageUrl + p.url)
    },

  },
  watch: {
    searchImage(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getGoogleImages()
    },
  },
  mounted() {
    if (this.uploadedPhotos && this.uploadedPhotos.length) {
      this.imageData.photos = this.uploadedPhotos
    }
  },
  methods: {
    ...mapActions({
      getCategories: 'productCategory/index',
      fileUpload: 'client/fileUpload',
      addPhotoProduct: 'productCategory/addPhotoProduct',
      getProductInfo: 'productCategory/show',
      updatePhotoProduct: 'productCategory/updatePhotoProduct',
    }),
    send() {
      this.imageData.product_category_id = this.model
        ? (this.model.category ? this.model.category.id : this.model.id)
        : this.$route.params.id
      this.imageData.photos.forEach((element, index) => {
        if (!element.url) {
          this.imageData.photos.splice(index, 1)
        }
      })
      this.addPhotoProduct(this.imageData).then(res => {
        showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
        this.$emit('close', true)
      })
    },
    addImage() {
      if (this.imageData.photos.length < 5 && !this.isHasNotUploaded) {
        this.imageData.photos.push({ url: '', is_main: 0 })
      }
    },
    async onChangeFile(event, index, directory) {
      console.log(9999, event, index, directory)
      if (event.target.files[0].size <= 15360000) {
        this.loads[`loading${index}`] = true
        const fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('directory', directory)
        this.fileUpload(fileData)
          .then(res => {
            this.imageData.photos[index].url = res.data.path
            if (this.imageData.photos.length < 5) {
              this.imageData.photos.push({ url: '', is_main: 0 })
            }
          })
          .catch(err => {
            showToast('danger', err.data.message, 'XIcon')
          })
          .finally(() => {
            this.loads[`loading${index}`] = false
          })
      } else {
        showToast(
          'danger',
          this.$t('Файл ҳажми 15 МБ дан кичик бўлиши лозим'),
          'XIcon',
        )
      }
    },
    deleteImg(index) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Вы действительно хотите удалить?'), {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Да'),
          cancelTitle: this.$t('Нет'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (
              this.imageData.photos.length > 1
              && this.imageData.photos[index].is_main
            ) {
              this.imageData.photos.splice(index, 1)
              this.imageData.photos[0].is_main = 1
              showToast('success', this.$t('Успешно удалено'))
            } else {
              this.imageData.photos.splice(index, 1)
              showToast('success', this.$t('Успешно удалено'))
            }
          }
        })
    },
    //
    async downloadImage(imageSrc, nameOfDownload = 'my-image.png') {
      try {
        const response = await axios.get(imageSrc, { responseType: 'blob' })
        const blob = response.data
        const fileName = imageSrc.split('/').pop()
        const file = new File([blob], fileName, { type: blob.type })
        const dataTransfer = new DataTransfer()
        dataTransfer.items.add(file)
        const fileInput = this.$refs.firstPassport[this.indexPhoto]
        fileInput.files = dataTransfer.files
        const event = { target: { files: fileInput.files } }
        console.log(777, event, this.indexPhoto, 'photo')
        setTimeout(() => {
          this.onChangeFile(event, this.indexPhoto, 'photo')
        }, 0)
        showToast('success', 'Muvaffaqiyatli yuklandi', 'CheckCircleIcon')
      } catch (error) {
        showToast('danger', 'Yuklab bo`lmadi, boshqa rasmni yuklashga urinib ko`ring', 'XIcon')
      }
    },
    showMoreImages() {
      this.page += 1
      this.getGoogleImages(true)
    },
    searchGoogleImage() {
      this.isGenerate = true
      if (this.productName) {
        this.searchImage = this.productName
      }
    },
    getGoogleImages: debounce(function (showMore = false) {
      this.searchLoading = true
      if (this.searchImage && this.searchImage.length >= 3) {
        axios
          .get(
            // `https://www.googleapis.com/customsearch/v1?q=${this.searchImage}&cx=558fff20ea33d410e&key=AIzaSyAzE-MSB2sxM7G_WF1W0P0V9fQ8S3C2euc&searchType=image&start=${this.page * this.per_page}`,
            `https://www.googleapis.com/customsearch/v1?q=${this.searchImage}&cx=${this.cx}&searchType=image&start=${this.page * this.per_page}`,
          )
          .then(res => {
            if (res.data.items) {
              if (showMore && typeof showMore === 'boolean') {
                this.generatedImages = [...this.generatedImages, ...res.data.items]
              } else {
                this.generatedImages = res.data.items
              }
            } else {
              showToast('danger', "So'rov topilmadi, qisqaroq so'zlar yordamida izlab ko'ring", 'XIcon')
            }
          })
          .catch(err => {
            this.cx = this.cx2
            showToast('danger', 'Limit tugadi yoki server xatoligi', 'XIcon')
          })
          .finally(() => {
            this.searchLoading = false
          })
      }
    }, 1000),
    // ,
    fetchProducts(name) {
      this.getCategories({
        level: 3,
        per_page: 9999,
        belongs_to_hired_worker: null,
        name_uz: name,
      }).then(response => {
        this.product_categories = response.data.data
      })
    },
    searchProduct: debounce(function (search) {
      if (search && search.length >= 2) {
        this.fetchProducts(search)
      }
    }, 500),
    selectedProduct(itemId) {
      this.getProductInfo({
        id: itemId,
        relations: 'photos',
      })
        .then(response => {
          this.newSelectedProductInfo = response.data
        })
    },
    setSelectedProduct() {
      if (this.newSelectedProductInfo) {
        console.log(88, this.model)
        const productCategoryId = this.model ? (this.model.category ? this.model.category.id : this.model.id) : this.$route.params.id
        this.addPhotoProduct({
          product_category_id: productCategoryId,
          photos: this.newSelectedProductInfo.photos,
        })
          .then(response => {
            this.newSelectedProductInfo = null
            showToast('success', 'Muvaffaqiyatli saqlandi')
            this.$emit('close', true)
          })
      }
    },
  },
}
</script>

<style>
.generated_photo {
  width: 162px;
}
</style>
