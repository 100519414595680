<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <div
          v-if="parent"
          class="mb-2"
        >
          <!-- Ushbu turga qo'shiladi -->
          {{ $t('Добавляется в эту категорию') }}: <b>{{ parent.models_with_parent.name_uz }}</b>
        </div>
        <b-form-group
          :label="$t('Цена наличие(USD)')"
          label-for="cost"
        >
          <validation-provider
            #default="{ errors }"
            name="cost"
            rules="required"
          >
            <cleave
              id="cost"
              v-model="form.price_dollar"
              class="form-control"
              :state="errors.length > 0 ? false:null"
              :options="cleaveOption"
              placeholder="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Цена рассрочка(USD)')"
          label-for="cost"
        >
          <validation-provider
            #default="{ errors }"
            name="cost"
            rules="required"
          >
            <cleave
              id="cost"
              v-model="form.price_dollar_installment"
              class="form-control"
              :state="errors.length>0?false:null"
              :options="cleaveOption"
              placeholder="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- <span></span> -->
        <b-button
          size="sm"
          variant="outline-primary mr-1"
          class=" mb-2"
          @click="openCalculateForm=!openCalculateForm"
        >
          <feather-icon
            icon="ClipboardIcon"
            size="12"
          />
          <span class="ml-25 align-middle">Muddatli to'lovni hisoblash</span>
        </b-button>

        <div
          v-if="openCalculateForm"
          class="primary-block"
        >
          <b-form-group
            :label="$t('Rassrochka muddati (oy)')"
            label-for="cost"
          >
            <validation-provider
              #default="{ errors }"
              name="credit_months"
              rules="required"
            >
              <cleave
                id="credit_months"
                v-model="form.credit_months"
                class="form-control"
                :state="errors.length>0?false:null"
                :options="cleaveOption"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            :label="$t('Boshlang`ich to`lov(USD)')"
            label-for="cost"
          >
            <cleave
              id="in_advance_payment_amount_dollar"
              v-model="form.in_advance_payment_amount_dollar"
              class="form-control"
              :options="cleaveOption"
              placeholder="0"
            />
          </b-form-group>
          <b-form-group
            :label="$t('Oylik to`lov(USD)')"
            label-for="cost"
          >
            <validation-provider
              #default="{ errors }"
              name="cost"
              rules="required"
            >
              <cleave
                id="cost"
                v-model="form.monthly_payment_amount_dollar"
                class="form-control"
                :state="errors.length>0?false:null"
                :options="cleaveOption"
                placeholder="0"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
            size="sm"
            variant="primary mr-1"
            @click="calculateInstallmentPrice"
          >          Hisoblash        </b-button>
          <template v-if="installmentPriceDollar">

            <h2 class="text-center mt-1">
              {{ installmentPriceDollar |formatPrice }} $
            </h2>
            <span>Ushbu summa <b>Цена рассрочка(USD)</b> qiymatiga o'rnatildi</span>
          </template>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, Cleave,
  },
  data() {
    return {
      form: {
        product_category_id: null,
        price_dollar: null,
        price_dollar_installment: null,
        in_advance_payment_amount_dollar: null,
        monthly_payment_amount_dollar: null,
        credit_months: 12,
      },
      openCalculateForm: false,
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      parent: null,
      installmentPriceDollar: null,
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({ cities: 'city/GET_ITEMS' }),
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
          this.parent = null
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Изменить цену')
  },
  methods: {
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess', res.data)
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    calculateInstallmentPrice() {
      console.log(1)
      this.calculateInstallmentPriceDolar(this.form).then(res => {
        this.installmentPriceDollar = res.data
        this.form.price_dollar_installment = res.data
      })
    },
    add(item) {
      this.parent = item
      if (item.models_with_parent) {
        this.form.product_category_id = item.category_id
        this.form.price_dollar = item.models_with_parent.price_dollar
        this.form.price_dollar_installment = item.models_with_parent.price_dollar_installment
        this.form.in_advance_payment_amount_dollar = item.models_with_parent.in_advance_payment_amount_dollar
      } else {
        this.form.product_category_id = item.id
        this.form.price_dollar = item.price_dollar
        this.form.price_dollar_installment = item.price_dollar_installment
        this.form.in_advance_payment_amount_dollar = item.in_advance_payment_amount_dollar
      }
      this.visible = true
    },
    method(data) {
      return this.update(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      update: 'productCategory/updateProductPrice',
      calculateInstallmentPriceDolar: 'productCategory/calculateInstallmentPriceDolar',
    }),
  },
}
</script>

<style scoped>

</style>
