<template>
  <div>
    <b-tabs
      v-if="!isLevier"
      v-model="statusTab"
    >
      <b-tab
        v-for="tab in tabs"
        :key="tab.tab"
        :title="tab.name"
        :active="tab.tab === 'active'"
        @click="tabStChange(tab.tab)"
      />
    </b-tabs>
    <div class="d-flex justify-content-start mb-2">
      <b-form-checkbox
        v-if="filterModel.tab == 'overdue'"
        v-model="filterModel.attached_me"
        class="mr-2"
        :value="1"
        :unchecked-value="null"
        @input="onChangeMine"
      >
        O'zimga tegishlilarni ko'rsatish
      </b-form-checkbox>
      <b-form-checkbox
        v-model="filterModel.is_dollar"
        :value="1"
        :unchecked-value="0"
        @input="onChangeCurr"
      >
        Valyutada tuzilganlar
      </b-form-checkbox>

      <b-form-checkbox
        v-model="filterModel.is_online"
        class="ml-4"
        :value="1"
        :unchecked-value="null"
        @input="onChangeOnline"
      >
        Online shartnomalar
      </b-form-checkbox>
    </div>
    <b-row>
      <b-col
        :md="7"
        class="mt-1 d-flex flex-wrap flex-row"
      >
        <x-date-picker
          v-model="filterModel.from_date"
          :placeholder="$t('Дата начала')"
          class="mr-1 mb-1"
          @input="getItems"
        />
        <x-date-picker
          v-model="filterModel.to_date"
          :placeholder="$t('Дата окончания')"
          class="mr-1 mb-1"
          @input="getItems"
        />
      </b-col>
      <b-col
        :md="3"
        class="mt-1"
      >
        <!-- <label label="v-phone-1">Nomi bo'yicha qidiruv</label> -->
        <b-form-input
          id="v-phone-1"
          v-model="filterModel.imei"
          placeholder="IMEI"
          @input="getItems"
        />
      </b-col>
      <b-col
        :md="2"
        class="mt-1"
      >
        <b-button
          v-can="'contracts-excel-download'"
          variant="success"
          class="btn-tour-skip text-right mb-2 float-right"
          :disabled="xlLoading"
          @click="tableToExcel"
        >
          <feather-icon
            v-if="xlLoading"
            icon="LoaderIcon"
            size="16"
          />
          <feather-icon
            v-else
            icon="DownloadIcon"
            size="16"
          />
          Юклаб олиш
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="filterModel.tab == 'overdue'">
      <b-col
        v-if="hasPermission('uni_access.sendSelectedContractsUniAccess') || hasPermission('uni_access.sendAllOverdueContractsUniAccess')"
        :md="6"
        class="mt-1"
      >
        <i>Shartnomalarni tanlab avtomatik pul yechish tizimizga yuborishingiz
          mumkin</i>
      </b-col>
      <b-col
        :md="6"
        class="mt-1"
      >
        <div
          v-can="'uni_access.sendAllOverdueContractsUniAccess'"
          class="float-right ml-2"
        >
          <b-overlay :show="loadingAutoPay">
            <b-button
              variant="outline-primary"
              class="mb-2"
              @click="confirmActionAll"
            >
              Barchasini yuborish
            </b-button>
          </b-overlay>
        </div>
        <div
          v-can="'uni_access.sendSelectedContractsUniAccess'"
          class="float-right"
        >
          <b-overlay :show="loadingAutoPay">
            <b-button
              variant="primary"
              class="mb-2 "
              :disabled="!contract_ids.length"
              @click="confirmActionSelected"
            >
              Tanlanganlarni yuborish
            </b-button>
          </b-overlay>
        </div>
      </b-col>
    </b-row>
    <b-overlay :show="loading">
      <good-table-column-search
        v-if="contracts"
        :items="contracts.data"
        :columns="columns"
        :total="contracts.total"
        :page="page"
        :has-show="true"
        model="payment"
        max-height="700px"
        :filter="filterModel"
        @getItems="getItems"
        @selectedRows="selectedRows"
        @onPageChange="(p) => (page = p)"
      />
      <template v-if="xlContracts && xlContracts.data">
        <GoodTableExcel
          v-show="false"
          ref="table"
          class="tablee"
          :items="xlContracts.data"
          :columns="xlColumns"
          :total="xlContracts.total"
        />
      </template>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import GoodTableColumnSearch from '@/views/table/vue-good-table/GoodTableColumnSearch.vue'
import GoodTableExcel from '@/views/table/vue-good-table/GoodTableExcel.vue'
import { showToast } from '@/utils/toast'

export default {
  name: 'Index',
  components: {
    GoodTableColumnSearch,
    GoodTableExcel,
  },
  data() {
    return {
      page: 1,
      loading: false,
      xlLoading: false,
      statusTab: 1,
      sss: 2,
      filterModel: {
        per_page: 50,
        number: null,
        tab: 'active',
        appends: '',
        attached_me: 0,
        from_date: null,
        to_date: null,
        client_id: null,
        is_dollar: null,
        is_online: null,
        relations: 'client|createdUser',
      },
      contract_ids: [],
      loadingAutoPay: false,
      tabs: [
        { status: 1, tab: 'active', name: 'Активные контракты' },
        { status: 2, tab: 'closed', name: 'Закрытые контракты' },
        { status: 3, tab: 'overdue', name: 'Просроченные контракты' },
        { status: 4, tab: 'rejected', name: 'Отмененные контракты' },
        { status: 5, tab: 'not_confirmed', name: 'Незавершенные контракты' },
        { status: 6, tab: 'all', name: 'Все' },
      ],
      columns: [],
      contracts: null,
      xlContracts: null,
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    isHasPermission() {
      return (
        this.user.role.name == 'cashier'
        || this.user.role.name == 'levier'
        || this.user.role.name == 'admin'
      )
    },
    title() {
      const activeTab = this.tabs.find(el => el.tab == this.filterModel.tab)
      if (activeTab) {
        return activeTab.name
      }
      return 'Список договора'
    },
    xlColumns() {
      const cols = [...this.firstCols]
      const n = cols.length
      const amountCols = [
        {
          label: this.$t('Срок оплата (тўлов куни)'),
          field: 'credit_payment_day',
        },
        {
          label: this.$t('Первоначальная сумма (бошланғич тўлов)'),
          field: 'in_advance_payment_amount',
          isAmount: true,
        },
        {
          label: this.$t('Сумма договор по приходу'),
          field: 'income_amount',
          isAmount: true,
        },
        {
          label: this.$t('Сумма договор по рассрочку'),
          field: 'credit_amount',
          isAmount: true,
        },
        {
          label: this.$t('Ежемесячно оплата'),
          field: 'monthlyPayment',
        },
        {
          label: this.$t('Выплаченная сумма (тўланган сумма)'),
          field: 'all_payment_amount',
          isAmount: true,
        },
        {
          label: this.$t('Остаток сумма рассрочки (жами тўланмаган сумма)'),
          field: 'all_debt_amount',
        },
        {
          label: this.$t('Просроченные дни'),
          field: 'overdue_days',
          isAmount: true,
        },
        {
          label: this.$t('Просроченная сумма'),
          field: 'overdue_amount',
          isAmount: true,
        },
        {
          label: this.$t('Просроченная сумма до этого месяца'),
          field: 'overdue_amount_until_this_month',
          isAmount: true,
        },
        {
          label: this.$t('Просроченная сумма в этом месяце'),
          field: 'overdue_amount_this_month',
          isAmount: true,
        },
        {
          label: this.$t('Просроченные дни в этом месяце'),
          field: 'overdue_days_this_month',
        },
        {
          label: this.$t('Undiruvchi'),
          field: 'levy_user_name',
        },
        {
          label: this.$t('Категория'),
          field: 'parent0_product_names_str',
        },
        {
          label: this.$t('Подкатегория'),
          field: 'parent1_product_names_str',
        },
        {
          label: this.$t('Номенклатура'),
          field: 'parent2_product_names_str',
        },
        {
          label: this.$t('Наименование товара'),
          field: 'product_names_str',
        },
        {
          label: this.$t('Телефон созаемщика'),
          field: 'client2.phone',
        },
        {
          label: this.$t('Дополнительные номера заявителья'),
          field: 'near_phone1',
        },
        {
          label: this.$t('Дополнительные номера заявителья'),
          field: 'near_phone2',
        },
        {
          label: this.$t('Rasmiylashtirgan xodim'),
          field: 'created_user.name',
        },
        {
          label: 'Ombor',
          field: 'warehouse.name_uz',
        },
      ]
      cols.splice(n - 1, 0, ...amountCols)

      if (this.filterModel.tab == 'all') {
        cols.splice(n - 1, 0, {
          label: this.$t('Статус'),
          field: 'status_name',
        })
      }
      return cols
    },
    firstCols() {
      return [
        {
          label: this.$t('Номер договора'),
          field: 'id',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Фамилия'),
          field: 'client.lastname',
          showField: this.hasPermission('contracts.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Имя'),
          field: 'client.firstname',
          showField: this.hasPermission('contracts.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Отчества'),
          field: 'client.middlename',
          showField: this.hasPermission('contracts.show'),
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Телефон'),
          field: 'client.phone',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('Паспорт'),
          field: this.passportField,
          filterField: 'passport',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: this.$t('ПИНФЛ'),
          field: 'client.pin',
          filterOptions: {
            enabled: true,
            placeholder: '',
          },
        },

        {
          label: this.$t('Дата договор'),
          field: 'start_date',
          isDate: true,
        },
        {
          label: this.$t('Срок договор'),
          field: 'credit_months',
        },
        {
          label: this.$t('Действия'),
          field: 'action',
          isOrders: true,
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    if (this.isLevier) {
      this.filterModel.status = 1
      this.statusTab = 3
      this.filterModel.is_overdue = true
    }
    if (this.$route.query && this.$route.query.client_id) {
      this.filterModel.client_id = this.$route.query.client_id
    }
    this.filterModel.to_date = this.today()
    if (this.$route.query.tab) {
      this.filterModel.tab = this.$route.query.tab
      const querytab = this.tabs.findIndex(tab => tab.tab === this.$route.query.tab)
      this.statusTab = querytab
      setTimeout(() => {
        this.statusTab = querytab
        this.tabStChange(this.$route.query.tab)
      }, 500)
    }
    if (this.$route.query.page) {
      setTimeout(() => {
        this.page = +this.$route.query.page
      }, 500)
    }
    if (this.$route.query.attached) {
      this.filterModel.attached_me = this.$route.query.attached
    }
    if (this.$route.query.currency) {
      this.filterModel.is_dollar = this.$route.query.currency
    }
    if (this.$route.query.is_online) {
      this.filterModel.is_online = this.$route.query.is_online
    }
    setTimeout(() => {
      this.getItems()
      this.getColumns()
    }, 100)
  },
  methods: {
    ...mapActions({
      getItemsAction: 'contract/index',
      destroyAction: 'region/destroy',
      getCitiesAction: 'city/index',
      destroyCityAction: 'city/destroy',
      sendSelectedContractsUniaccess: 'resource/sendSelectedContractsUniaccess',
      sendAllOverdueContractsUniaccess: 'resource/sendAllOverdueContractsUniaccess',
    }),
    getColumns() {
      this.columns = [...this.firstCols]
    },
    selectedRows(rows) {
      this.contract_ids = []
      if (rows && rows.length) {
        rows.forEach(el => {
          this.contract_ids.push(el.id)
        })
      }
    },
    onChangeMine() {
      this.getItems()
      this.changeQuery('attached', this.filterModel.attached_me)
    },
    onChangeCurr() {
      this.getItems()
      this.changeQuery('currency', this.filterModel.is_dollar)
    },
    onChangeOnline() {
      this.getItems()
      this.changeQuery('is_online', this.filterModel.is_online)
    },
    confirmActionSelected() {
      this.$bvModal
        .msgBoxConfirm(`Tanlagan ${this.contract_ids.length}ta shartnomalarni avtoto'lovga yuborish orqali ularning kartasidan avtomatik to'lanmagan qarzlari yechib olinadi`, {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Tasdiqlash'),
          cancelTitle: this.$t('Bekor qilish'),
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(value => {
          if (value) {
            this.loadingAutoPay = true
            this.sendSelectedContractsUniaccess({ contract_ids: this.contract_ids }).then(res => {
              if (res.success) {
                this.getItems()
                this.contract_ids = []
                showToast('success', res.data)
              }
            })
              .finally(() => {
                this.loadingAutoPay = false
              })
          }
        })
    },
    confirmActionAll() {
      this.$bvModal
        .msgBoxConfirm(`Barcha ${this.contracts.total}ta shartnomalarni avtoto'lovga yuborish orqali ularning kartasidan avtomatik to'lanmagan qarzlari yechib olinadi`, {
          title: this.$t('Подтвердите'),
          size: 'sm',
          variant: 'warning',
          okVariant: 'primary',
          okTitle: this.$t('Tasdiqlash'),
          cancelTitle: this.$t('Bekor qilish'),
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(value => {
          if (value) {
            this.loadingAutoPay = true
            this.sendAllOverdueContractsUniaccess().then(res => {
              if (res.success) {
                this.getItems()
                this.contract_ids = []
                showToast('success', res.data)
              }
            })
              .finally(() => {
                this.loadingAutoPay = false
              })
          }
        })
    },

    passportField(row) {
      if (row.client && row.client.doc_series) {
        return row.client.doc_series + row.client.doc_number
      }
      return null
    },
    tabChange(status) {
      if (status == 3) {
        this.filterModel.status = 1
        this.filterModel.is_overdue = true
        this.filterModel.images_confirmation_status = 1
      } else if (status == 4) {
        this.filterModel.status = 1
        this.filterModel.is_rejected = 1
        this.filterModel.is_overdue = null
        this.filterModel.images_confirmation_status = null
      } else if (status == 5) {
        this.filterModel.images_confirmation_status = null
        this.filterModel.status = 1
        this.filterModel.is_rejected = null
        this.filterModel.is_overdue = null
      } else {
        this.filterModel.images_confirmation_status = 1
        this.filterModel.status = status
        this.filterModel.is_rejected = 0
        this.filterModel.is_overdue = null
      }

      this.getItems()
    },
    tabStChange(tab) {
      this.filterModel.tab = tab
      this.changeQuery('tab', tab)
      this.page = 1
      if (tab == 'overdue') {
        this.filterModel.per_page = 10
        this.filterModel.appends = 'overdue_days|overdue_amount_until_this_month|overdue_amount_this_month|overdue_days_this_month|levy_user_name'
        this.getColumns()
        const n = this.columns.length
        setTimeout(() => {
          this.columns.splice(
            n - 1,
            0,
            {
              label: this.$t('Просроченные дни'),
              field: 'overdue_days',
              isDangerAmount: true,
              filterOptions: {
                enabled: true,
                placeholder: '',
              },
            },
            {
              label: this.$t('Просроченная сумма '),
              field: 'overdue_amount',
              isDangerAmount: true,
              filterOptions: {
                enabled: true,
                placeholder: '',
              },
            },
            {
              label: this.$t('Просроченная сумма до этого месяца'),
              field: 'overdue_amount_until_this_month',
              isDangerAmount: true,
            },
            {
              label: this.$t('Просроченная сумма в этом месяце'),
              field: 'overdue_amount_this_month',
              isDangerAmount: true,
            },
            {
              label: this.$t('Просроченные дни в этом месяце'),
              field: 'overdue_days_this_month',
              isDangerAmount: true,
            },
            {
              label: this.$t('Undiruvchi'),
              field: 'levy_user_name',
            },
          )
        }, 0)
      } else if (tab == 'all') {
        if (this.filterModel.appends) {
          this.filterModel.appends = `${this.filterModel.appends}|status_name`
        } else {
          this.filterModel.appends = 'status_name'
        }
        this.getColumns()
        const n = this.columns.length
        setTimeout(() => {
          this.columns.splice(n - 1, 0, {
            label: this.$t('Статус'),
            field: 'status_name',
          })
        }, 0)
      } else {
        this.filterModel.appends = ''
        this.getColumns()
      }
      setTimeout(() => {
        this.getItems()
      }, 0)
    },
    getItems() {
      this.loading = true
      this.getItemsAction({ ...this.filterModel, page: this.page })
        .then(res => {
          this.contracts = res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getXlItems() {
      this.xlLoading = true
      const filter = {
        page: 1,
        per_page: 9999,
        tab: this.filterModel.tab,
        from_date: this.filterModel.from_date,
        to_date: this.filterModel.to_date,
        appends: '',
        client_id: this.filterModel.client_id,
        is_dollar: this.filterModel.is_dollar,
        is_online: this.filterModel.is_online,
        attached_me: this.filterModel.attached_me,
        relations: 'client|client2|createdUser|warehouse',
      }
      filter.appends = 'overdue_days|overdue_amount_until_this_month|overdue_amount_this_month|overdue_days_this_month|levy_user_name|status_name|income_amount|product_names_str|parent2_product_names_str|parent1_product_names_str|parent0_product_names_str'
      await this.getItemsAction(filter)
        .then(res => {
          this.xlContracts = res.data
        })
        .finally(() => {
          this.xlLoading = false
        })
    },
    today() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    async tableToExcel(table) {
      await this.getXlItems()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )
      const secondRow = tableeee.querySelector('thead tr:nth-child(2)')
      if (secondRow) {
        secondRow.remove()
      }
      const rows = tableeee.querySelectorAll('table tr')
      rows.forEach(row => {
        const lastCell = row.lastElementChild
        // const firstCell = row.firstElementChild
        // firstCell.remove()
        if (lastCell.querySelector('td.vgt-left-align svg')) {
          lastCell.remove()
        }
      })
      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        let title = `${this.title}(${this.formatDateYmd(
          this.today(),
          'DD.MM.YYYY',
        )})`
        if (this.filterModel.from_date) {
          title = `${this.title} (${this.formatDateYmd(
            this.filterModel.from_date,
            'DD.MM.YYYY',
          )}-${this.formatDateYmd(
            this.filterModel.to_date,
            'DD.MM.YYYY',
          )})`
        }
        link.download = `${title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
      }, 10)
    },
  },
}
</script>

<style scoped></style>
