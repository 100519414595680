<template>
  <b-modal
    v-model="visible"
    hide-footer
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    centered
    :title="title"
  >
    <validation-observer
      ref="form"
      #default="{ invalid }"
    >
      <b-form class="auth-login-form mt-2">
        <b-form-group
          :label="$t('Ismi')"
          label-for="name"
        >
          <b-form-input
            id="name"
            v-model="form.firstname"
            name="name"
            :disabled="!!itemInfo"
            placeholder=""
          />
        </b-form-group>
        <b-form-group
          :label="$t('Familiyasi')"
          label-for="lastname"
        >
          <b-form-input
            id="lastname"
            v-model="form.lastname"
            name="lastname"
            :disabled="!!itemInfo"
            :placeholder="$t('Familiya')"
          />
        </b-form-group>
        <b-form-group
          :label="$t('Email')"
          label-for="mail"
        >
          <validation-provider
            #default="{ errors }"
            name="mail"
            rules="required"
          >
            <b-form-input
              id="mail"
              v-model="form.mail"
              :disabled="!!itemInfo"
              :state="errors.length > 3 ? false : null"
              name="mail"
              type="email"
              :placeholder="$t('test@mail.com')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- <b-form-group
          :label="$t('iCloud')"
          label-for="icloud"
        >
          <validation-provider
            #default="{ errors }"
            name="icloud"
            rules="required"
          >
            <b-form-input
              id="icloud"
              v-model="form.icloud"
              type="email"
              :state="errors.length > 3 ? false:null"
              name="icloud"
              :placeholder="$t('test@icloud.com')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group> -->
        <b-form-group
          :label="$t('Mijoz telefoni')"
          label-for="v-phone"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Mijoz telefoni')"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                +998
              </b-input-group-prepend>

              <b-form-input
                id="v-phone"
                v-model="form.phone"
                v-mask="'#########'"
                :disabled="!!itemInfo"
                name="v-phone"
                :state="errors.length > 3 ? false : null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Icloud telefoni')"
          label-for="v-phone"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Icloud telefoni')"
            rules="required"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                +998
              </b-input-group-prepend>

              <b-form-input
                id="v-phone"
                v-model="form.icloud_phone"
                v-mask="'#########'"
                name="v-phone"
                :state="errors.length > 3 ? false : null"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="user-new-password"
          :label="$t('Icloud paroli')"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="form.id ? '' : 'required'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="user-new-password"
                v-model="form.icloud_password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="icloud parol"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="mail-password"
          :label="$t('Email paroli')"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="form.id ? '' : 'required'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="mail-password"
                v-model="form.mail_password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="Email parol"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>

    <b-overlay :show="loading">
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="w-100"
        variant="primary"
        @click="save"
      >
        Saqlash
      </b-button>
    </b-overlay>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions } from 'vuex'
import { clearObject } from '@/utils'

export default {
  name: 'Create',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    itemInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: {
        mail: '',
        icloud: '',
        password: '',
        icloud_password: '',
        mail_password: '',
        phone: '',
        icloud_phone: '',
        firstname: '',
        lastname: '',
      },
      title: '',
      visible: false,
      loading: false,
      required,
      passwordFieldTypeNew: 'password',
    }
  },
  computed: {
    itemId() {
      return this.$route.query.id
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) {
        setTimeout(() => {
          clearObject(this.form)
          if (this.itemInfo) {
            this.setForm()
          }
        }, 200)
      }
    },
  },
  mounted() {
    this.title = this.$t('Добавить значение')
    if (this.itemInfo) {
      this.setForm()
    }
    this.$root.$on('add', data => {
      this.visible = true
    })
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    setForm() {
      this.form.mail = this.itemInfo.mail
      this.form.icloud = this.itemInfo.icloud
      this.form.password = this.itemInfo.password
      this.form.icloud_password = this.itemInfo.icloud_password
      this.form.mail_password = this.itemInfo.mail_password
      this.form.phone = this.itemInfo.phone
      this.form.icloud_phone = this.itemInfo.icloud_phone
      this.form.firstname = this.itemInfo.firstname
      this.form.lastname = this.itemInfo.lastname
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.loading = true
        this.method(this.form)
          .then(res => {
            showToast(
              'success',
              this.$t('Успешно сохранено'),
              'CheckCircleIcon',
            )
            this.$emit('onSuccess')
            this.visible = false
          })
          .catch(err => {
            // if (err.status === 422) {
            //   this.$refs.form.setErrors(err.data.errors)
            // }
            showToast('danger', this.$t('Ошибка'), 'XIcon')
          }).finally(() => {
            this.loading = false
          })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    edit(item) {
      this.form.id = item.id
      this.form.name_uz = item.name_uz
      this.form.name_ru = item.name_ru
      this.visible = true
    },
    method(data) {
      if (this.itemInfo) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      storeItem: 'resource/storeICloud',
      updateItem: 'resource/updateICloud',
    }),
  },
}
</script>

<style scoped></style>
