<template>
  <b-modal
    v-model="visible"
    cancel-variant="outline-secondary"
    :ok-title="$t('Сохранить')"
    no-close-on-backdrop
    :cancel-title="$t('Отмена')"
    centered
    :title="title"
    @ok.prevent="save"
  >
    <validation-observer
      ref="form"
      #default="{invalid}"
    >
      <b-form
        class="auth-login-form mt-2"
      >
        <b-form-group
          :label="$t('ФИО')"
          label-for="name"
        >
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <b-form-input
              id="name"
              v-model="form.name"
              :state="errors.length > 3 ? false:null"
              name="name"
              placeholder=""
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Логин')"
          label-for="login"
        >
          <validation-provider
            #default="{ errors }"
            name="login"
            rules="required"
          >
            <b-form-input
              id="login"
              v-model="form.login"
              :state="errors.length > 3 ? false:null"
              name="login"
              :placeholder="$t('Логин')"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Роль')"
          label-for="role"
        >
          <validation-provider
            #default="{ errors }"
            name="role_id"
            rules="required"
          >
            <b-form-select
              id="role"
              v-model="form.role_id"
              :state="errors.length > 0 ? false:null"
              name="role"
              value-field="id"
              text-field="display_name"
              :placeholder="$t('Выберите роль')"
              :options="roles"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          v-if="isSalerRole"
          :label="$t('На какой склад')"
          label-for="warehouse_id"
        >
          <validation-provider
            #default="{ errors }"
            name="warehouse_id"
            rules="required"
          >
            <b-form-select
              id="warehouse_id"
              v-model="form.warehouse_id"
              :state="errors.length > 0 ? false:null"
              name="name"
              value-field="id"
              text-field="name_uz"
              :placeholder="$t('На какой склад')"
              :options="warehouses.data"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <template v-if="isHWorker">
          <b-form-group
            :label="$t('Выберите область')"
            label-for="region_id"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Выберите область')"
              rules="required"
            >
              <b-form-select
                id="region_id"
                v-model="form.region_id"
                :state="errors.length > 0 ? false:null"
                name="name"
                value-field="id"
                text-field="name_uz"
                :placeholder="$t('Выберите область')"
                :options="regions.data"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Выберите район')"
            label-for="city_id"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Выберите район')"
              rules="required"
            >
              <b-form-select
                id="city_id"
                v-model="form.city_id"
                :state="errors.length > 0 ? false:null"
                name="name"
                value-field="id"
                text-field="name_uz"
                :placeholder="$t('Выберите район')"
                :options="cities.data"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Адрес')"
            label-for="address"
          >
            <validation-provider
              #default="{ errors }"
              name="address"
              rules="required"
            >
              <b-form-input
                id="address"
                v-model="form.address"
                :state="errors.length > 3 ? false:null"
                name="name"
                placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Введите телефон')"
            label-for="v-phone"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Введите телефон')"
              rules="required"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend
                  is-text
                >
                  +998
                </b-input-group-prepend>

                <b-form-input
                  id="v-phone"
                  v-model="form.phone"
                  v-mask="'#########'"
                  name="v-phone"
                  :state="errors.length > 3 ? false:null"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </template>
        <b-form-group
          :label="$t('Oklad (so`m)')"
          label-for="oklad"
        >
          <validation-provider
            #default="{ errors }"
            name="oklad"
            rules="required"
          >
            <cleave
              id="price"
              v-model="form.oklad"
              class="form-control"
              :options="cleaveOption"
              :state="errors.length > 3 ? false:null"
              placeholder="0"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Ishga kirgan sana"
          label-for="enter_date"
        >
          <validation-provider
            #default="{ errors }"
            name="enter_date"
            rules="required"
          >
            <x-date-picker
              v-model="form.enter_date"
              :state="errors.length > 3 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          :label="$t('Статус')"
          label-for="status"
        >
          <b-form-checkbox
            v-model="form.status"
            checked="true"
            :value="1"
            :unchecked-value="0"
            name="check-button"
            switch
            inline
          >
            {{ $t('Актив') }}
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          label-for="user-new-password"
          :label="$t('Новый парол')"
        >
          <validation-provider
            #default="{ errors }"
            name="password"
            vid="password"
            :rules="form.id?'':'required'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="user-new-password"
                v-model="form.password"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label-for="account-retype-new-password"
          :label="$t('Повторите новый пароль')"
        >
          <validation-provider
            #default="{ errors }"
            name="retype_password"
            :rules="form.id?'':'required|confirmed:password'"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="form.retype_password"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { showToast } from '@/utils/toast'
import { required } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import { clearObject } from '@/utils'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Create',
  components: {
    ValidationProvider, ValidationObserver, Cleave,
  },
  data() {
    return {
      form: {
        id: null,
        name: null,
        login: null,
        role_id: null,
        password: null,
        status: 1,
        oklad: null,
        enter_date: null,
        retype_password: null,
        region_id: null,
        city_id: null,
        phone: null,
        address: null,
      },
      isHWorker: false,
      isSalerRole: false,
      roles: [],
      passwordFieldTypeNew: 'password',
      cleaveOption: { numeral: true, numeralThousandsGroupStyle: 'thousand' },
      passwordFieldTypeRetype: 'password',
      title: '',
      visible: false,
      required,
    }
  },
  computed: {
    ...mapGetters({
      warehouses: 'warehouse/GET_ITEMS', cities: 'city/GET_ITEMS', regions: 'region/GET_ITEMS',
    }),
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordEqual() {
      return this.form.password === this.form.retype_password
    },
  },
  watch: {
    visible(newVal) {
      if (!newVal) setTimeout(() => { clearObject(this.form) }, 200)
    },
    'form.role_id': function (val) {
      const role = this.roles.find(el => el.id === val)
      if (val && (role.is_warehouse_id_required === 1)) {
        this.isSalerRole = true
        this.getWarehousAction(this.getAll)
      } else if (role.name == 'hired_worker') {
        this.isHWorker = true
        this.getRegionsAction({ per_page: 999 })
      } else {
        this.isSalerRole = false
        this.isHWorker = false
      }
    },
    'form.region_id': function (newVal) {
      if (newVal) {
        this.getCities(newVal)
      }
    },
  },
  mounted() {
    this.title = this.$t('Добавить пользаватель')
    this.$root.$on('add', data => {
      this.visible = true
    })
    this.fetchRoles()
      .then(res => {
        if (res.success) {
          this.roles = res.data.data
        }
      })
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    getCities(cityId) {
      this.fetchCities({ region_id: cityId })
    },
    async save() {
      const valid = await this.validationForm()
      if (valid) {
        this.method(this.form).then(res => {
          showToast('success', this.$t('Успешно сохранено'), 'CheckCircleIcon')
          this.$emit('onSuccess')
          this.visible = false
        }).catch(err => {
          if (err.status === 422) {
            this.$refs.form.setErrors(err.data.errors)
          }
          showToast('danger', this.$t('Ошибка'), 'XIcon')
        })
      } else {
        showToast('warning', this.$t('Заполните необходимые поля'))
      }
    },
    edit(item) {
      this.getItem({ id: item.id, relations: 'hiredWorkerWarehouse|provider' }).then(res => {
        if (res.data.hired_worker_warehouse) {
          this.form.city_id = res.data.hired_worker_warehouse.city_id
          this.form.region_id = res.data.hired_worker_warehouse.region_id
          this.form.address = res.data.hired_worker_warehouse.address
        }
        if (res.data.provider) {
          this.form.phone = res.data.provider.phone
        }
      })
      this.form.id = item.id
      this.form.name = item.name
      this.form.login = item.login
      this.form.role_id = item.role_id
      this.form.password = item.show_password
      this.form.warehouse_id = item.warehouse_id
      this.form.retype_password = item.show_password
      this.form.status = item.status
      this.form.oklad = item.oklad
      this.form.enter_date = item.enter_date
      this.visible = true
    },
    method(data) {
      if (this.form.id) return this.updateItem(data)
      return this.storeItem(data)
    },
    async validationForm() {
      let validated = false
      await this.$refs.form.validate().then(success => {
        validated = success
      })
      return validated
    },
    ...mapActions({
      getRegionsAction: 'region/index',
      fetchCities: 'city/index',
      store: 'region/store',
      update: 'region/update',
      storeItem: 'user/store',
      getItem: 'user/show',
      updateItem: 'user/update',
      fetchRoles: 'resource/roles',
      getWarehousAction: 'warehouse/index',
    }),
  },
}
</script>

<style scoped>

</style>
