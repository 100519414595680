var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('Сохранить'),"no-close-on-backdrop":"","cancel-title":_vm.$t('Отмена'),"centered":"","title":_vm.title},on:{"ok":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('validation-observer',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2"},[_c('b-form-group',{attrs:{"label":_vm.$t('ФИО'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 3 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Логин'),"label-for":"login"}},[_c('validation-provider',{attrs:{"name":"login","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"login","state":errors.length > 3 ? false:null,"name":"login","placeholder":_vm.$t('Логин')},model:{value:(_vm.form.login),callback:function ($$v) {_vm.$set(_vm.form, "login", $$v)},expression:"form.login"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Роль'),"label-for":"role"}},[_c('validation-provider',{attrs:{"name":"role_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"role","state":errors.length > 0 ? false:null,"name":"role","value-field":"id","text-field":"display_name","placeholder":_vm.$t('Выберите роль'),"options":_vm.roles},model:{value:(_vm.form.role_id),callback:function ($$v) {_vm.$set(_vm.form, "role_id", $$v)},expression:"form.role_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.isSalerRole)?_c('b-form-group',{attrs:{"label":_vm.$t('На какой склад'),"label-for":"warehouse_id"}},[_c('validation-provider',{attrs:{"name":"warehouse_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"warehouse_id","state":errors.length > 0 ? false:null,"name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('На какой склад'),"options":_vm.warehouses.data},model:{value:(_vm.form.warehouse_id),callback:function ($$v) {_vm.$set(_vm.form, "warehouse_id", $$v)},expression:"form.warehouse_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.isHWorker)?[_c('b-form-group',{attrs:{"label":_vm.$t('Выберите область'),"label-for":"region_id"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Выберите область'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"region_id","state":errors.length > 0 ? false:null,"name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите область'),"options":_vm.regions.data},model:{value:(_vm.form.region_id),callback:function ($$v) {_vm.$set(_vm.form, "region_id", $$v)},expression:"form.region_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Выберите район'),"label-for":"city_id"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Выберите район'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"city_id","state":errors.length > 0 ? false:null,"name":"name","value-field":"id","text-field":"name_uz","placeholder":_vm.$t('Выберите район'),"options":_vm.cities.data},model:{value:(_vm.form.city_id),callback:function ($$v) {_vm.$set(_vm.form, "city_id", $$v)},expression:"form.city_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Адрес'),"label-for":"address"}},[_c('validation-provider',{attrs:{"name":"address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","state":errors.length > 3 ? false:null,"name":"name","placeholder":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Введите телефон'),"label-for":"v-phone"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Введите телефон'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +998 ")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"id":"v-phone","name":"v-phone","state":errors.length > 3 ? false:null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]:_vm._e(),_c('b-form-group',{attrs:{"label":_vm.$t('Oklad (so`m)'),"label-for":"oklad"}},[_c('validation-provider',{attrs:{"name":"oklad","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",attrs:{"id":"price","options":_vm.cleaveOption,"state":errors.length > 3 ? false:null,"placeholder":"0"},model:{value:(_vm.form.oklad),callback:function ($$v) {_vm.$set(_vm.form, "oklad", $$v)},expression:"form.oklad"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Ishga kirgan sana","label-for":"enter_date"}},[_c('validation-provider',{attrs:{"name":"enter_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('x-date-picker',{attrs:{"state":errors.length > 3 ? false:null},model:{value:(_vm.form.enter_date),callback:function ($$v) {_vm.$set(_vm.form, "enter_date", $$v)},expression:"form.enter_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Статус'),"label-for":"status"}},[_c('b-form-checkbox',{attrs:{"checked":"true","value":1,"unchecked-value":0,"name":"check-button","switch":"","inline":""},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" "+_vm._s(_vm.$t('Актив'))+" ")])],1),_c('b-form-group',{attrs:{"label-for":"user-new-password","label":_vm.$t('Новый парол')}},[_c('validation-provider',{attrs:{"name":"password","vid":"password","rules":_vm.form.id?'':'required'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"user-new-password","type":_vm.passwordFieldTypeNew,"name":"new-password","placeholder":"New Password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"account-retype-new-password","label":_vm.$t('Повторите новый пароль')}},[_c('validation-provider',{attrs:{"name":"retype_password","rules":_vm.form.id?'':'required|confirmed:password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-input',{attrs:{"id":"account-retype-new-password","type":_vm.passwordFieldTypeRetype,"name":"retype-password","placeholder":"New Password"},model:{value:(_vm.form.retype_password),callback:function ($$v) {_vm.$set(_vm.form, "retype_password", $$v)},expression:"form.retype_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconRetype},on:{"click":_vm.togglePasswordRetype}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }